@import url("https://cdn.syncfusion.com/ej2/material.css");
@import "~stream-chat-react/dist/css/index.css";

.syncfusion-tooltip-header {
  background: #f00;
}

g[id*="Zooming_KitCollection"] {
  opacity: 0.5;
}

g[id*="Zooming_KitCollection"]:hover {
  opacity: 1;
}

/* syncfusion date popup 

.e-popup[style] {
  scale: 0.85;
} 

*/

.e-popup {
  scale: 0.85;
  top: 280px !important;
}

/* To specify height and font size */
/* .e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  border-bottom: 0px;
} */

/* To specify background color and font size */
/* .e-input-group .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  background-color: darkgray;
  font-size: 104px;
} */

/* To specify background and height */
/* .e-daterangepicker.e-popup .e-range-header {
  height: 70px;
} */

/* To specify color and font size */
/* .e-daterangepicker.e-popup .e-range-header .e-start-label,
.e-daterangepicker.e-popup .e-range-header .e-end-label {
  color: brown;
  font-size: 30px;
} */

/* To specify background color */
/* .e-daterangepicker.e-popup .e-calendar {
  background-color: brown;
} */

/* To specify color and font size */
/* .e-daterangepicker.e-popup .e-calendar .e-header .e-title {
  color: beige;
  font-size: 20px;
} */

/* To specify font size */
/* .e-calendar .e-header .e-prev,
.e-calendar .e-header .e-next,
.e-bigger.e-small .e-calendar .e-header .e-prev,
.e-bigger.e-small .e-calendar .e-header .e-next {
  font-size: 20px;
} */

/* To specify background color and border */
/* .e-calendar .e-content td:hover span.e-day {
} */

/* To specify background color and border color */
/* .e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup
  .e-footer
  .e-css.e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup
  .e-footer
  .e-css.e-btn.e-apply.e-flat.e-primary:disabled {
} */

/* To specify background color, color, and border color */
/* .e-daterangepicker.e-popup .e-footer .e-btn.e-flat,
.e-daterangepicker.e-popup .e-footer .e-css.e-btn.e-flat {
} */

/* To specify background color, color, and border color */
/* .e-daterangepicker.e-popup .e-footer {
} */

/* To specify background and border */
/* .e-calendar .e-content td.e-focused-date.e-today span.e-day {
} */

/* .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #e3165b;
} */
